import React, { useEffect } from 'react';
// import * as dat from 'dat.gui';

export default class WebGL extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

    if (typeof window !== undefined) {
    window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
    ga('create', 'UA-105392568-1', 'auto');
    ga('send', 'pageview');

    var canvas = document.getElementsByTagName('canvas')[0];
    resizeCanvas();
    
    var config = {
      SIM_RESOLUTION: 128,
      DYE_RESOLUTION: 1024,
      CAPTURE_RESOLUTION: 512,
      DENSITY_DISSIPATION: 1,
      VELOCITY_DISSIPATION: 0.2,
      PRESSURE: 0.8,
      PRESSURE_ITERATIONS: 20,
      CURL: 30,
      SPLAT_RADIUS: 0.25,
      SPLAT_FORCE: 6000,
      SHADING: true,
      COLORFUL: true,
      COLOR_UPDATE_SPEED: 10,
      PAUSED: false,
      BACK_COLOR: { r: 0, g: 0, b: 0 },
      TRANSPARENT: false,
      BLOOM: true,
      BLOOM_ITERATIONS: 8,
      BLOOM_RESOLUTION: 256,
      BLOOM_INTENSITY: 0.8,
      BLOOM_THRESHOLD: 0.6,
      BLOOM_SOFT_KNEE: 0.7,
      SUNRAYS: true,
      SUNRAYS_RESOLUTION: 196,
      SUNRAYS_WEIGHT: 1.0,
    };
    
    function pointerPrototype() {
      this.id = -1;
      this.texcoordX = 0;
      this.texcoordY = 0;
      this.prevTexcoordX = 0;
      this.prevTexcoordY = 0;
      this.deltaX = 0;
      this.deltaY = 0;
      this.down = false;
      this.moved = false;
      this.color = [30, 0, 300];
    }
    
    var pointers = [];
    var splatStack = [];
    pointers.push(new pointerPrototype());
    
    var ref = getWebGLContext(canvas);
    var gl = ref.gl;
    var ext = ref.ext;
    
    if (isMobile()) {
      config.DYE_RESOLUTION = 512;
    }
    if (!ext.supportLinearFiltering) {
      config.DYE_RESOLUTION = 512;
      config.SHADING = false;
      config.BLOOM = false;
      config.SUNRAYS = false;
    }
    
    // startGUI();
    
    function getWebGLContext(canvas) {
      var params = {
        alpha: true,
        depth: false,
        stencil: false,
        antialias: false,
        preserveDrawingBuffer: false,
      };
    
      var gl = canvas.getContext('webgl2', params);
      var isWebGL2 = !!gl;
      if (!isWebGL2) {
        gl = canvas.getContext('webgl', params) || canvas.getContext('experimental-webgl', params);
      }
    
      var halfFloat;
      var supportLinearFiltering;
      if (isWebGL2) {
        gl.getExtension('EXT_color_buffer_float');
        supportLinearFiltering = gl.getExtension('OES_texture_float_linear');
      } else {
        halfFloat = gl.getExtension('OES_texture_half_float');
        supportLinearFiltering = gl.getExtension('OES_texture_half_float_linear');
      }
    
      gl.clearColor(0.0, 0.0, 0.0, 1.0);
    
      var halfFloatTexType = isWebGL2 ? gl.HALF_FLOAT : halfFloat.HALF_FLOAT_OES;
      var formatRGBA;
      var formatRG;
      var formatR;
    
      if (isWebGL2) {
        formatRGBA = getSupportedFormat(gl, gl.RGBA16F, gl.RGBA, halfFloatTexType);
        formatRG = getSupportedFormat(gl, gl.RG16F, gl.RG, halfFloatTexType);
        formatR = getSupportedFormat(gl, gl.R16F, gl.RED, halfFloatTexType);
      } else {
        formatRGBA = getSupportedFormat(gl, gl.RGBA, gl.RGBA, halfFloatTexType);
        formatRG = getSupportedFormat(gl, gl.RGBA, gl.RGBA, halfFloatTexType);
        formatR = getSupportedFormat(gl, gl.RGBA, gl.RGBA, halfFloatTexType);
      }
    
      ga(
        'send',
        'event',
        isWebGL2 ? 'webgl2' : 'webgl',
        formatRGBA == null ? 'not supported' : 'supported'
      );
    
      return {
        gl: gl,
        ext: {
          formatRGBA: formatRGBA,
          formatRG: formatRG,
          formatR: formatR,
          halfFloatTexType: halfFloatTexType,
          supportLinearFiltering: supportLinearFiltering,
        },
      };
    }
    
    function getSupportedFormat(gl, internalFormat, format, type) {
      if (!supportRenderTextureFormat(gl, internalFormat, format, type)) {
        switch (internalFormat) {
          case gl.R16F:
            return getSupportedFormat(gl, gl.RG16F, gl.RG, type);
          case gl.RG16F:
            return getSupportedFormat(gl, gl.RGBA16F, gl.RGBA, type);
          default:
            return null;
        }
      }
    
      return {
        internalFormat: internalFormat,
        format: format,
      };
    }
    
    function supportRenderTextureFormat(gl, internalFormat, format, type) {
      var texture = gl.createTexture();
      gl.bindTexture(gl.TEXTURE_2D, texture);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
      gl.texImage2D(gl.TEXTURE_2D, 0, internalFormat, 4, 4, 0, format, type, null);
    
      var fbo = gl.createFramebuffer();
      gl.bindFramebuffer(gl.FRAMEBUFFER, fbo);
      gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);
    
      var status = gl.checkFramebufferStatus(gl.FRAMEBUFFER);
      return status == gl.FRAMEBUFFER_COMPLETE;
    }
    
    // function startGUI() {
    //   var gui = new dat.GUI({ width: 300 });
    //   gui
    //     .add(config, 'DYE_RESOLUTION', { high: 1024, medium: 512, low: 256, 'very low': 128 })
    //     .name('quality')
    //     .onFinishChange(initFramebuffers);
    //   gui
    //     .add(config, 'SIM_RESOLUTION', { '32': 32, '64': 64, '128': 128, '256': 256 })
    //     .name('sim resolution')
    //     .onFinishChange(initFramebuffers);
    //   gui.add(config, 'DENSITY_DISSIPATION', 0, 4.0).name('density diffusion');
    //   gui.add(config, 'VELOCITY_DISSIPATION', 0, 4.0).name('velocity diffusion');
    //   gui.add(config, 'PRESSURE', 0.0, 1.0).name('pressure');
    //   gui.add(config, 'CURL', 0, 50).name('vorticity').step(1);
    //   gui.add(config, 'SPLAT_RADIUS', 0.01, 1.0).name('splat radius');
    //   gui.add(config, 'SHADING').name('shading').onFinishChange(updateKeywords);
    //   gui.add(config, 'COLORFUL').name('colorful');
    //   gui.add(config, 'PAUSED').name('paused').listen();
    
    //   gui
    //     .add(
    //       {
    //         fun: function () {
    //           splatStack.push(parseInt(Math.random() * 20) + 5);
    //         },
    //       },
    //       'fun'
    //     )
    //     .name('Random splats');
    
    //   var bloomFolder = gui.addFolder('Bloom');
    //   bloomFolder.add(config, 'BLOOM').name('enabled').onFinishChange(updateKeywords);
    //   bloomFolder.add(config, 'BLOOM_INTENSITY', 0.1, 2.0).name('intensity');
    //   bloomFolder.add(config, 'BLOOM_THRESHOLD', 0.0, 1.0).name('threshold');
    
    //   var sunraysFolder = gui.addFolder('Sunrays');
    //   sunraysFolder.add(config, 'SUNRAYS').name('enabled').onFinishChange(updateKeywords);
    //   sunraysFolder.add(config, 'SUNRAYS_WEIGHT', 0.3, 1.0).name('weight');
    
    //   var captureFolder = gui.addFolder('Capture');
    //   captureFolder.addColor(config, 'BACK_COLOR').name('background color');
    //   captureFolder.add(config, 'TRANSPARENT').name('transparent');
    //   captureFolder.add({ fun: captureScreenshot }, 'fun').name('take screenshot');
    
    //   if (isMobile()) {
    //     gui.close();
    //   }
    // }
    
    function isMobile() {
      return /Mobi|Android/i.test(navigator.userAgent);
    }
    
    function captureScreenshot() {
      var res = getResolution(config.CAPTURE_RESOLUTION);
      var target = createFBO(
        res.width,
        res.height,
        ext.formatRGBA.internalFormat,
        ext.formatRGBA.format,
        ext.halfFloatTexType,
        gl.NEAREST
      );
      render(target);
    
      var texture = framebufferToTexture(target);
      texture = normalizeTexture(texture, target.width, target.height);
    
      var captureCanvas = textureToCanvas(texture, target.width, target.height);
      var datauri = captureCanvas.toDataURL();
      downloadURI('fluid.png', datauri);
      URL.revokeObjectURL(datauri);
    }
    
    function framebufferToTexture(target) {
      gl.bindFramebuffer(gl.FRAMEBUFFER, target.fbo);
      var length = target.width * target.height * 4;
      var texture = new Float32Array(length);
      gl.readPixels(0, 0, target.width, target.height, gl.RGBA, gl.FLOAT, texture);
      return texture;
    }
    
    function normalizeTexture(texture, width, height) {
      var result = new Uint8Array(texture.length);
      var id = 0;
      for (var i = height - 1; i >= 0; i--) {
        for (var j = 0; j < width; j++) {
          var nid = i * width * 4 + j * 4;
          result[nid + 0] = clamp01(texture[id + 0]) * 255;
          result[nid + 1] = clamp01(texture[id + 1]) * 255;
          result[nid + 2] = clamp01(texture[id + 2]) * 255;
          result[nid + 3] = clamp01(texture[id + 3]) * 255;
          id += 4;
        }
      }
      return result;
    }
    
    function clamp01(input) {
      return Math.min(Math.max(input, 0), 1);
    }
    
    function textureToCanvas(texture, width, height) {
      var captureCanvas = document.createElement('canvas');
      var ctx = captureCanvas.getContext('2d');
      captureCanvas.width = width;
      captureCanvas.height = height;
    
      var imageData = ctx.createImageData(width, height);
      imageData.data.set(texture);
      ctx.putImageData(imageData, 0, 0);
    
      return captureCanvas;
    }
    
    function downloadURI(filename, uri) {
      var link = document.createElement('a');
      link.download = filename;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    
    var Material = function Material(vertexShader, fragmentShaderSource) {
      this.vertexShader = vertexShader;
      this.fragmentShaderSource = fragmentShaderSource;
      this.programs = [];
      this.activeProgram = null;
      this.uniforms = [];
    };
    
    Material.prototype.setKeywords = function setKeywords(keywords) {
      var hash = 0;
      for (var i = 0; i < keywords.length; i++) {
        hash += hashCode(keywords[i]);
      }
    
      var program = this.programs[hash];
      if (program == null) {
        var fragmentShader = compileShader(gl.FRAGMENT_SHADER, this.fragmentShaderSource, keywords);
        program = createProgram(this.vertexShader, fragmentShader);
        this.programs[hash] = program;
      }
    
      if (program == this.activeProgram) {
        return;
      }
    
      this.uniforms = getUniforms(program);
      this.activeProgram = program;
    };
    
    Material.prototype.bind = function bind() {
      gl.useProgram(this.activeProgram);
    };
    
    var Program = function Program(vertexShader, fragmentShader) {
      this.uniforms = {};
      this.program = createProgram(vertexShader, fragmentShader);
      this.uniforms = getUniforms(this.program);
    };
    
    Program.prototype.bind = function bind() {
      gl.useProgram(this.program);
    };
    
    function createProgram(vertexShader, fragmentShader) {
      var program = gl.createProgram();
      gl.attachShader(program, vertexShader);
      gl.attachShader(program, fragmentShader);
      gl.linkProgram(program);
    
      if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
        throw gl.getProgramInfoLog(program);
      }
    
      return program;
    }
    
    function getUniforms(program) {
      var uniforms = [];
      var uniformCount = gl.getProgramParameter(program, gl.ACTIVE_UNIFORMS);
      for (var i = 0; i < uniformCount; i++) {
        var uniformName = gl.getActiveUniform(program, i).name;
        uniforms[uniformName] = gl.getUniformLocation(program, uniformName);
      }
      return uniforms;
    }
    
    function compileShader(type, source, keywords) {
      source = addKeywords(source, keywords);
    
      var shader = gl.createShader(type);
      gl.shaderSource(shader, source);
      gl.compileShader(shader);
    
      if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        throw gl.getShaderInfoLog(shader);
      }
    
      return shader;
    }
    
    function addKeywords(source, keywords) {
      if (keywords == null) {
        return source;
      }
      var keywordsString = '';
      keywords.forEach(function (keyword) {
        keywordsString += '#define ' + keyword + '\n';
      });
      return keywordsString + source;
    }
    
    var baseVertexShader = compileShader(
      gl.VERTEX_SHADER,
      '\n    precision highp float;\n\n    attribute vec2 aPosition;\n    varying vec2 vUv;\n    varying vec2 vL;\n    varying vec2 vR;\n    varying vec2 vT;\n    varying vec2 vB;\n    uniform vec2 texelSize;\n\n    void main () {\n        vUv = aPosition * 0.5 + 0.5;\n        vL = vUv - vec2(texelSize.x, 0.0);\n        vR = vUv + vec2(texelSize.x, 0.0);\n        vT = vUv + vec2(0.0, texelSize.y);\n        vB = vUv - vec2(0.0, texelSize.y);\n        gl_Position = vec4(aPosition, 0.0, 1.0);\n    }\n'
    );
    
    var blurVertexShader = compileShader(
      gl.VERTEX_SHADER,
      '\n    precision highp float;\n\n    attribute vec2 aPosition;\n    varying vec2 vUv;\n    varying vec2 vL;\n    varying vec2 vR;\n    uniform vec2 texelSize;\n\n    void main () {\n        vUv = aPosition * 0.5 + 0.5;\n        float offset = 1.33333333;\n        vL = vUv - texelSize * offset;\n        vR = vUv + texelSize * offset;\n        gl_Position = vec4(aPosition, 0.0, 1.0);\n    }\n'
    );
    
    var blurShader = compileShader(
      gl.FRAGMENT_SHADER,
      '\n    precision mediump float;\n    precision mediump sampler2D;\n\n    varying vec2 vUv;\n    varying vec2 vL;\n    varying vec2 vR;\n    uniform sampler2D uTexture;\n\n    void main () {\n        vec4 sum = texture2D(uTexture, vUv) * 0.29411764;\n        sum += texture2D(uTexture, vL) * 0.35294117;\n        sum += texture2D(uTexture, vR) * 0.35294117;\n        gl_FragColor = sum;\n    }\n'
    );
    
    var copyShader = compileShader(
      gl.FRAGMENT_SHADER,
      '\n    precision mediump float;\n    precision mediump sampler2D;\n\n    varying highp vec2 vUv;\n    uniform sampler2D uTexture;\n\n    void main () {\n        gl_FragColor = texture2D(uTexture, vUv);\n    }\n'
    );
    
    var clearShader = compileShader(
      gl.FRAGMENT_SHADER,
      '\n    precision mediump float;\n    precision mediump sampler2D;\n\n    varying highp vec2 vUv;\n    uniform sampler2D uTexture;\n    uniform float value;\n\n    void main () {\n        gl_FragColor = value * texture2D(uTexture, vUv);\n    }\n'
    );
    
    var colorShader = compileShader(
      gl.FRAGMENT_SHADER,
      '\n    precision mediump float;\n\n    uniform vec4 color;\n\n    void main () {\n        gl_FragColor = color;\n    }\n'
    );
    
    var checkerboardShader = compileShader(
      gl.FRAGMENT_SHADER,
      '\n    precision highp float;\n    precision highp sampler2D;\n\n    varying vec2 vUv;\n    uniform sampler2D uTexture;\n    uniform float aspectRatio;\n\n    #define SCALE 25.0\n\n    void main () {\n        vec2 uv = floor(vUv * SCALE * vec2(aspectRatio, 1.0));\n        float v = mod(uv.x + uv.y, 2.0);\n        v = v * 0.1 + 0.8;\n        gl_FragColor = vec4(vec3(v), 1.0);\n    }\n'
    );
    
    var displayShaderSource =
      '\n    precision highp float;\n    precision highp sampler2D;\n\n    varying vec2 vUv;\n    varying vec2 vL;\n    varying vec2 vR;\n    varying vec2 vT;\n    varying vec2 vB;\n    uniform sampler2D uTexture;\n    uniform sampler2D uBloom;\n    uniform sampler2D uSunrays;\n    uniform sampler2D uDithering;\n    uniform vec2 ditherScale;\n    uniform vec2 texelSize;\n\n    vec3 linearToGamma (vec3 color) {\n        color = max(color, vec3(0));\n        return max(1.055 * pow(color, vec3(0.416666667)) - 0.055, vec3(0));\n    }\n\n    void main () {\n        vec3 c = texture2D(uTexture, vUv).rgb;\n\n    #ifdef SHADING\n        vec3 lc = texture2D(uTexture, vL).rgb;\n        vec3 rc = texture2D(uTexture, vR).rgb;\n        vec3 tc = texture2D(uTexture, vT).rgb;\n        vec3 bc = texture2D(uTexture, vB).rgb;\n\n        float dx = length(rc) - length(lc);\n        float dy = length(tc) - length(bc);\n\n        vec3 n = normalize(vec3(dx, dy, length(texelSize)));\n        vec3 l = vec3(0.0, 0.0, 1.0);\n\n        float diffuse = clamp(dot(n, l) + 0.7, 0.7, 1.0);\n        c *= diffuse;\n    #endif\n\n    #ifdef BLOOM\n        vec3 bloom = texture2D(uBloom, vUv).rgb;\n    #endif\n\n    #ifdef SUNRAYS\n        float sunrays = texture2D(uSunrays, vUv).r;\n        c *= sunrays;\n    #ifdef BLOOM\n        bloom *= sunrays;\n    #endif\n    #endif\n\n    #ifdef BLOOM\n        float noise = texture2D(uDithering, vUv * ditherScale).r;\n        noise = noise * 2.0 - 1.0;\n        bloom += noise / 255.0;\n        bloom = linearToGamma(bloom);\n        c += bloom;\n    #endif\n\n        float a = max(c.r, max(c.g, c.b));\n        gl_FragColor = vec4(c, a);\n    }\n';
    
    var bloomPrefilterShader = compileShader(
      gl.FRAGMENT_SHADER,
      '\n    precision mediump float;\n    precision mediump sampler2D;\n\n    varying vec2 vUv;\n    uniform sampler2D uTexture;\n    uniform vec3 curve;\n    uniform float threshold;\n\n    void main () {\n        vec3 c = texture2D(uTexture, vUv).rgb;\n        float br = max(c.r, max(c.g, c.b));\n        float rq = clamp(br - curve.x, 0.0, curve.y);\n        rq = curve.z * rq * rq;\n        c *= max(rq, br - threshold) / max(br, 0.0001);\n        gl_FragColor = vec4(c, 0.0);\n    }\n'
    );
    
    var bloomBlurShader = compileShader(
      gl.FRAGMENT_SHADER,
      '\n    precision mediump float;\n    precision mediump sampler2D;\n\n    varying vec2 vL;\n    varying vec2 vR;\n    varying vec2 vT;\n    varying vec2 vB;\n    uniform sampler2D uTexture;\n\n    void main () {\n        vec4 sum = vec4(0.0);\n        sum += texture2D(uTexture, vL);\n        sum += texture2D(uTexture, vR);\n        sum += texture2D(uTexture, vT);\n        sum += texture2D(uTexture, vB);\n        sum *= 0.25;\n        gl_FragColor = sum;\n    }\n'
    );
    
    var bloomFinalShader = compileShader(
      gl.FRAGMENT_SHADER,
      '\n    precision mediump float;\n    precision mediump sampler2D;\n\n    varying vec2 vL;\n    varying vec2 vR;\n    varying vec2 vT;\n    varying vec2 vB;\n    uniform sampler2D uTexture;\n    uniform float intensity;\n\n    void main () {\n        vec4 sum = vec4(0.0);\n        sum += texture2D(uTexture, vL);\n        sum += texture2D(uTexture, vR);\n        sum += texture2D(uTexture, vT);\n        sum += texture2D(uTexture, vB);\n        sum *= 0.25;\n        gl_FragColor = sum * intensity;\n    }\n'
    );
    
    var sunraysMaskShader = compileShader(
      gl.FRAGMENT_SHADER,
      '\n    precision highp float;\n    precision highp sampler2D;\n\n    varying vec2 vUv;\n    uniform sampler2D uTexture;\n\n    void main () {\n        vec4 c = texture2D(uTexture, vUv);\n        float br = max(c.r, max(c.g, c.b));\n        c.a = 1.0 - min(max(br * 20.0, 0.0), 0.8);\n        gl_FragColor = c;\n    }\n'
    );
    
    var sunraysShader = compileShader(
      gl.FRAGMENT_SHADER,
      '\n    precision highp float;\n    precision highp sampler2D;\n\n    varying vec2 vUv;\n    uniform sampler2D uTexture;\n    uniform float weight;\n\n    #define ITERATIONS 16\n\n    void main () {\n        float Density = 0.3;\n        float Decay = 0.95;\n        float Exposure = 0.7;\n\n        vec2 coord = vUv;\n        vec2 dir = vUv - 0.5;\n\n        dir *= 1.0 / float(ITERATIONS) * Density;\n        float illuminationDecay = 1.0;\n\n        float color = texture2D(uTexture, vUv).a;\n\n        for (int i = 0; i < ITERATIONS; i++)\n        {\n            coord -= dir;\n            float col = texture2D(uTexture, coord).a;\n            color += col * illuminationDecay * weight;\n            illuminationDecay *= Decay;\n        }\n\n        gl_FragColor = vec4(color * Exposure, 0.0, 0.0, 1.0);\n    }\n'
    );
    
    var splatShader = compileShader(
      gl.FRAGMENT_SHADER,
      '\n    precision highp float;\n    precision highp sampler2D;\n\n    varying vec2 vUv;\n    uniform sampler2D uTarget;\n    uniform float aspectRatio;\n    uniform vec3 color;\n    uniform vec2 point;\n    uniform float radius;\n\n    void main () {\n        vec2 p = vUv - point.xy;\n        p.x *= aspectRatio;\n        vec3 splat = exp(-dot(p, p) / radius) * color;\n        vec3 base = texture2D(uTarget, vUv).xyz;\n        gl_FragColor = vec4(base + splat, 1.0);\n    }\n'
    );
    
    var advectionShader = compileShader(
      gl.FRAGMENT_SHADER,
      '\n    precision highp float;\n    precision highp sampler2D;\n\n    varying vec2 vUv;\n    uniform sampler2D uVelocity;\n    uniform sampler2D uSource;\n    uniform vec2 texelSize;\n    uniform vec2 dyeTexelSize;\n    uniform float dt;\n    uniform float dissipation;\n\n    vec4 bilerp (sampler2D sam, vec2 uv, vec2 tsize) {\n        vec2 st = uv / tsize - 0.5;\n\n        vec2 iuv = floor(st);\n        vec2 fuv = fract(st);\n\n        vec4 a = texture2D(sam, (iuv + vec2(0.5, 0.5)) * tsize);\n        vec4 b = texture2D(sam, (iuv + vec2(1.5, 0.5)) * tsize);\n        vec4 c = texture2D(sam, (iuv + vec2(0.5, 1.5)) * tsize);\n        vec4 d = texture2D(sam, (iuv + vec2(1.5, 1.5)) * tsize);\n\n        return mix(mix(a, b, fuv.x), mix(c, d, fuv.x), fuv.y);\n    }\n\n    void main () {\n    #ifdef MANUAL_FILTERING\n        vec2 coord = vUv - dt * bilerp(uVelocity, vUv, texelSize).xy * texelSize;\n        vec4 result = bilerp(uSource, coord, dyeTexelSize);\n    #else\n        vec2 coord = vUv - dt * texture2D(uVelocity, vUv).xy * texelSize;\n        vec4 result = texture2D(uSource, coord);\n    #endif\n        float decay = 1.0 + dissipation * dt;\n        gl_FragColor = result / decay;\n    }',
      ext.supportLinearFiltering ? null : ['MANUAL_FILTERING']
    );
    
    var divergenceShader = compileShader(
      gl.FRAGMENT_SHADER,
      '\n    precision mediump float;\n    precision mediump sampler2D;\n\n    varying highp vec2 vUv;\n    varying highp vec2 vL;\n    varying highp vec2 vR;\n    varying highp vec2 vT;\n    varying highp vec2 vB;\n    uniform sampler2D uVelocity;\n\n    void main () {\n        float L = texture2D(uVelocity, vL).x;\n        float R = texture2D(uVelocity, vR).x;\n        float T = texture2D(uVelocity, vT).y;\n        float B = texture2D(uVelocity, vB).y;\n\n        vec2 C = texture2D(uVelocity, vUv).xy;\n        if (vL.x < 0.0) { L = -C.x; }\n        if (vR.x > 1.0) { R = -C.x; }\n        if (vT.y > 1.0) { T = -C.y; }\n        if (vB.y < 0.0) { B = -C.y; }\n\n        float div = 0.5 * (R - L + T - B);\n        gl_FragColor = vec4(div, 0.0, 0.0, 1.0);\n    }\n'
    );
    
    var curlShader = compileShader(
      gl.FRAGMENT_SHADER,
      '\n    precision mediump float;\n    precision mediump sampler2D;\n\n    varying highp vec2 vUv;\n    varying highp vec2 vL;\n    varying highp vec2 vR;\n    varying highp vec2 vT;\n    varying highp vec2 vB;\n    uniform sampler2D uVelocity;\n\n    void main () {\n        float L = texture2D(uVelocity, vL).y;\n        float R = texture2D(uVelocity, vR).y;\n        float T = texture2D(uVelocity, vT).x;\n        float B = texture2D(uVelocity, vB).x;\n        float vorticity = R - L - T + B;\n        gl_FragColor = vec4(0.5 * vorticity, 0.0, 0.0, 1.0);\n    }\n'
    );
    
    var vorticityShader = compileShader(
      gl.FRAGMENT_SHADER,
      '\n    precision highp float;\n    precision highp sampler2D;\n\n    varying vec2 vUv;\n    varying vec2 vL;\n    varying vec2 vR;\n    varying vec2 vT;\n    varying vec2 vB;\n    uniform sampler2D uVelocity;\n    uniform sampler2D uCurl;\n    uniform float curl;\n    uniform float dt;\n\n    void main () {\n        float L = texture2D(uCurl, vL).x;\n        float R = texture2D(uCurl, vR).x;\n        float T = texture2D(uCurl, vT).x;\n        float B = texture2D(uCurl, vB).x;\n        float C = texture2D(uCurl, vUv).x;\n\n        vec2 force = 0.5 * vec2(abs(T) - abs(B), abs(R) - abs(L));\n        force /= length(force) + 0.0001;\n        force *= curl * C;\n        force.y *= -1.0;\n\n        vec2 vel = texture2D(uVelocity, vUv).xy;\n        gl_FragColor = vec4(vel + force * dt, 0.0, 1.0);\n    }\n'
    );
    
    var pressureShader = compileShader(
      gl.FRAGMENT_SHADER,
      '\n    precision mediump float;\n    precision mediump sampler2D;\n\n    varying highp vec2 vUv;\n    varying highp vec2 vL;\n    varying highp vec2 vR;\n    varying highp vec2 vT;\n    varying highp vec2 vB;\n    uniform sampler2D uPressure;\n    uniform sampler2D uDivergence;\n\n    void main () {\n        float L = texture2D(uPressure, vL).x;\n        float R = texture2D(uPressure, vR).x;\n        float T = texture2D(uPressure, vT).x;\n        float B = texture2D(uPressure, vB).x;\n        float C = texture2D(uPressure, vUv).x;\n        float divergence = texture2D(uDivergence, vUv).x;\n        float pressure = (L + R + B + T - divergence) * 0.25;\n        gl_FragColor = vec4(pressure, 0.0, 0.0, 1.0);\n    }\n'
    );
    
    var gradientSubtractShader = compileShader(
      gl.FRAGMENT_SHADER,
      '\n    precision mediump float;\n    precision mediump sampler2D;\n\n    varying highp vec2 vUv;\n    varying highp vec2 vL;\n    varying highp vec2 vR;\n    varying highp vec2 vT;\n    varying highp vec2 vB;\n    uniform sampler2D uPressure;\n    uniform sampler2D uVelocity;\n\n    void main () {\n        float L = texture2D(uPressure, vL).x;\n        float R = texture2D(uPressure, vR).x;\n        float T = texture2D(uPressure, vT).x;\n        float B = texture2D(uPressure, vB).x;\n        vec2 velocity = texture2D(uVelocity, vUv).xy;\n        velocity.xy -= vec2(R - L, T - B);\n        gl_FragColor = vec4(velocity, 0.0, 1.0);\n    }\n'
    );
    
    var blit = (function () {
      gl.bindBuffer(gl.ARRAY_BUFFER, gl.createBuffer());
      gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([-1, -1, -1, 1, 1, 1, 1, -1]), gl.STATIC_DRAW);
      gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, gl.createBuffer());
      gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array([0, 1, 2, 0, 2, 3]), gl.STATIC_DRAW);
      gl.vertexAttribPointer(0, 2, gl.FLOAT, false, 0, 0);
      gl.enableVertexAttribArray(0);
    
      return function (destination) {
        gl.bindFramebuffer(gl.FRAMEBUFFER, destination);
        gl.drawElements(gl.TRIANGLES, 6, gl.UNSIGNED_SHORT, 0);
      };
    })();
    
    var dye;
    var velocity;
    var divergence;
    var curl;
    var pressure;
    var bloom;
    var bloomFramebuffers = [];
    var sunrays;
    var sunraysTemp;
    
    var ditheringTexture = createTextureAsync('LDR_LLL1_0.png');
    
    var blurProgram = new Program(blurVertexShader, blurShader);
    var copyProgram = new Program(baseVertexShader, copyShader);
    var clearProgram = new Program(baseVertexShader, clearShader);
    var colorProgram = new Program(baseVertexShader, colorShader);
    var checkerboardProgram = new Program(baseVertexShader, checkerboardShader);
    var bloomPrefilterProgram = new Program(baseVertexShader, bloomPrefilterShader);
    var bloomBlurProgram = new Program(baseVertexShader, bloomBlurShader);
    var bloomFinalProgram = new Program(baseVertexShader, bloomFinalShader);
    var sunraysMaskProgram = new Program(baseVertexShader, sunraysMaskShader);
    var sunraysProgram = new Program(baseVertexShader, sunraysShader);
    var splatProgram = new Program(baseVertexShader, splatShader);
    var advectionProgram = new Program(baseVertexShader, advectionShader);
    var divergenceProgram = new Program(baseVertexShader, divergenceShader);
    var curlProgram = new Program(baseVertexShader, curlShader);
    var vorticityProgram = new Program(baseVertexShader, vorticityShader);
    var pressureProgram = new Program(baseVertexShader, pressureShader);
    var gradienSubtractProgram = new Program(baseVertexShader, gradientSubtractShader);
    
    var displayMaterial = new Material(baseVertexShader, displayShaderSource);
    
    function initFramebuffers() {
      var simRes = getResolution(config.SIM_RESOLUTION);
      var dyeRes = getResolution(config.DYE_RESOLUTION);
    
      var texType = ext.halfFloatTexType;
      var rgba = ext.formatRGBA;
      var rg = ext.formatRG;
      var r = ext.formatR;
      var filtering = ext.supportLinearFiltering ? gl.LINEAR : gl.NEAREST;
    
      if (dye == null) {
        dye = createDoubleFBO(
          dyeRes.width,
          dyeRes.height,
          rgba.internalFormat,
          rgba.format,
          texType,
          filtering
        );
      } else {
        dye = resizeDoubleFBO(
          dye,
          dyeRes.width,
          dyeRes.height,
          rgba.internalFormat,
          rgba.format,
          texType,
          filtering
        );
      }
    
      if (velocity == null) {
        velocity = createDoubleFBO(
          simRes.width,
          simRes.height,
          rg.internalFormat,
          rg.format,
          texType,
          filtering
        );
      } else {
        velocity = resizeDoubleFBO(
          velocity,
          simRes.width,
          simRes.height,
          rg.internalFormat,
          rg.format,
          texType,
          filtering
        );
      }
    
      divergence = createFBO(
        simRes.width,
        simRes.height,
        r.internalFormat,
        r.format,
        texType,
        gl.NEAREST
      );
      curl = createFBO(simRes.width, simRes.height, r.internalFormat, r.format, texType, gl.NEAREST);
      pressure = createDoubleFBO(
        simRes.width,
        simRes.height,
        r.internalFormat,
        r.format,
        texType,
        gl.NEAREST
      );
    
      initBloomFramebuffers();
      initSunraysFramebuffers();
    }
    
    function initBloomFramebuffers() {
      var res = getResolution(config.BLOOM_RESOLUTION);
    
      var texType = ext.halfFloatTexType;
      var rgba = ext.formatRGBA;
      var filtering = ext.supportLinearFiltering ? gl.LINEAR : gl.NEAREST;
    
      bloom = createFBO(res.width, res.height, rgba.internalFormat, rgba.format, texType, filtering);
    
      bloomFramebuffers.length = 0;
      for (var i = 0; i < config.BLOOM_ITERATIONS; i++) {
        var width = res.width >> (i + 1);
        var height = res.height >> (i + 1);
    
        if (width < 2 || height < 2) {
          break;
        }
    
        var fbo = createFBO(width, height, rgba.internalFormat, rgba.format, texType, filtering);
        bloomFramebuffers.push(fbo);
      }
    }
    
    function initSunraysFramebuffers() {
      var res = getResolution(config.SUNRAYS_RESOLUTION);
    
      var texType = ext.halfFloatTexType;
      var r = ext.formatR;
      var filtering = ext.supportLinearFiltering ? gl.LINEAR : gl.NEAREST;
    
      sunrays = createFBO(res.width, res.height, r.internalFormat, r.format, texType, filtering);
      sunraysTemp = createFBO(res.width, res.height, r.internalFormat, r.format, texType, filtering);
    }
    
    function createFBO(w, h, internalFormat, format, type, param) {
      gl.activeTexture(gl.TEXTURE0);
      var texture = gl.createTexture();
      gl.bindTexture(gl.TEXTURE_2D, texture);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, param);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, param);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
      gl.texImage2D(gl.TEXTURE_2D, 0, internalFormat, w, h, 0, format, type, null);
    
      var fbo = gl.createFramebuffer();
      gl.bindFramebuffer(gl.FRAMEBUFFER, fbo);
      gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);
      gl.viewport(0, 0, w, h);
      gl.clear(gl.COLOR_BUFFER_BIT);
    
      var texelSizeX = 1.0 / w;
      var texelSizeY = 1.0 / h;
    
      return {
        texture: texture,
        fbo: fbo,
        width: w,
        height: h,
        texelSizeX: texelSizeX,
        texelSizeY: texelSizeY,
        attach: function attach(id) {
          gl.activeTexture(gl.TEXTURE0 + id);
          gl.bindTexture(gl.TEXTURE_2D, texture);
          return id;
        },
      };
    }
    
    function createDoubleFBO(w, h, internalFormat, format, type, param) {
      var fbo1 = createFBO(w, h, internalFormat, format, type, param);
      var fbo2 = createFBO(w, h, internalFormat, format, type, param);
    
      return {
        width: w,
        height: h,
        texelSizeX: fbo1.texelSizeX,
        texelSizeY: fbo1.texelSizeY,
        get read() {
          return fbo1;
        },
        set read(value) {
          fbo1 = value;
        },
        get write() {
          return fbo2;
        },
        set write(value) {
          fbo2 = value;
        },
        swap: function swap() {
          var temp = fbo1;
          fbo1 = fbo2;
          fbo2 = temp;
        },
      };
    }
    
    function resizeFBO(target, w, h, internalFormat, format, type, param) {
      var newFBO = createFBO(w, h, internalFormat, format, type, param);
      copyProgram.bind();
      gl.uniform1i(copyProgram.uniforms.uTexture, target.attach(0));
      blit(newFBO.fbo);
      return newFBO;
    }
    
    function resizeDoubleFBO(target, w, h, internalFormat, format, type, param) {
      if (target.width == w && target.height == h) {
        return target;
      }
      target.read = resizeFBO(target.read, w, h, internalFormat, format, type, param);
      target.write = createFBO(w, h, internalFormat, format, type, param);
      target.width = w;
      target.height = h;
      target.texelSizeX = 1.0 / w;
      target.texelSizeY = 1.0 / h;
      return target;
    }
    
    function createTextureAsync(url) {
      var texture = gl.createTexture();
      gl.bindTexture(gl.TEXTURE_2D, texture);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT);
      gl.texImage2D(
        gl.TEXTURE_2D,
        0,
        gl.RGB,
        1,
        1,
        0,
        gl.RGB,
        gl.UNSIGNED_BYTE,
        new Uint8Array([255, 255, 255])
      );
    
      var obj = {
        texture: texture,
        width: 1,
        height: 1,
        attach: function attach(id) {
          gl.activeTexture(gl.TEXTURE0 + id);
          gl.bindTexture(gl.TEXTURE_2D, texture);
          return id;
        },
      };
    
      var image = new Image();
      image.onload = function () {
        obj.width = image.width;
        obj.height = image.height;
        gl.bindTexture(gl.TEXTURE_2D, texture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGB, gl.RGB, gl.UNSIGNED_BYTE, image);
      };
      image.src = url;
    
      return obj;
    }
    
    function updateKeywords() {
      var displayKeywords = [];
      if (config.SHADING) {
        displayKeywords.push('SHADING');
      }
      if (config.BLOOM) {
        displayKeywords.push('BLOOM');
      }
      if (config.SUNRAYS) {
        displayKeywords.push('SUNRAYS');
      }
      displayMaterial.setKeywords(displayKeywords);
    }
    
    updateKeywords();
    initFramebuffers();
    multipleSplats(parseInt(Math.random() * 20) + 5);
    
    var lastUpdateTime = Date.now();
    var colorUpdateTimer = 0.0;
    update();
    
    function update() {
      var dt = calcDeltaTime();
      if (resizeCanvas()) {
        initFramebuffers();
      }
      updateColors(dt);
      applyInputs();
      if (!config.PAUSED) {
        step(dt);
      }
      render(null);
      requestAnimationFrame(update);
    }
    
    function calcDeltaTime() {
      var now = Date.now();
      var dt = (now - lastUpdateTime) / 1000;
      dt = Math.min(dt, 0.016666);
      lastUpdateTime = now;
      return dt;
    }
    
    function resizeCanvas() {
      var width = scaleByPixelRatio(canvas.clientWidth);
      var height = scaleByPixelRatio(canvas.clientHeight);
      if (canvas.width != width || canvas.height != height) {
        canvas.width = width;
        canvas.height = height;
        return true;
      }
      return false;
    }
    
    function updateColors(dt) {
      if (!config.COLORFUL) {
        return;
      }
    
      colorUpdateTimer += dt * config.COLOR_UPDATE_SPEED;
      if (colorUpdateTimer >= 1) {
        colorUpdateTimer = wrap(colorUpdateTimer, 0, 1);
        pointers.forEach(function (p) {
          p.color = generateColor();
        });
      }
    }
    
    function applyInputs() {
      if (splatStack.length > 0) {
        multipleSplats(splatStack.pop());
      }
    
      pointers.forEach(function (p) {
        if (p.moved) {
          p.moved = false;
          splatPointer(p);
        }
      });
    }
    
    function step(dt) {
      gl.disable(gl.BLEND);
      gl.viewport(0, 0, velocity.width, velocity.height);
    
      curlProgram.bind();
      gl.uniform2f(curlProgram.uniforms.texelSize, velocity.texelSizeX, velocity.texelSizeY);
      gl.uniform1i(curlProgram.uniforms.uVelocity, velocity.read.attach(0));
      blit(curl.fbo);
    
      vorticityProgram.bind();
      gl.uniform2f(vorticityProgram.uniforms.texelSize, velocity.texelSizeX, velocity.texelSizeY);
      gl.uniform1i(vorticityProgram.uniforms.uVelocity, velocity.read.attach(0));
      gl.uniform1i(vorticityProgram.uniforms.uCurl, curl.attach(1));
      gl.uniform1f(vorticityProgram.uniforms.curl, config.CURL);
      gl.uniform1f(vorticityProgram.uniforms.dt, dt);
      blit(velocity.write.fbo);
      velocity.swap();
    
      divergenceProgram.bind();
      gl.uniform2f(divergenceProgram.uniforms.texelSize, velocity.texelSizeX, velocity.texelSizeY);
      gl.uniform1i(divergenceProgram.uniforms.uVelocity, velocity.read.attach(0));
      blit(divergence.fbo);
    
      clearProgram.bind();
      gl.uniform1i(clearProgram.uniforms.uTexture, pressure.read.attach(0));
      gl.uniform1f(clearProgram.uniforms.value, config.PRESSURE);
      blit(pressure.write.fbo);
      pressure.swap();
    
      pressureProgram.bind();
      gl.uniform2f(pressureProgram.uniforms.texelSize, velocity.texelSizeX, velocity.texelSizeY);
      gl.uniform1i(pressureProgram.uniforms.uDivergence, divergence.attach(0));
      for (var i = 0; i < config.PRESSURE_ITERATIONS; i++) {
        gl.uniform1i(pressureProgram.uniforms.uPressure, pressure.read.attach(1));
        blit(pressure.write.fbo);
        pressure.swap();
      }
    
      gradienSubtractProgram.bind();
      gl.uniform2f(gradienSubtractProgram.uniforms.texelSize, velocity.texelSizeX, velocity.texelSizeY);
      gl.uniform1i(gradienSubtractProgram.uniforms.uPressure, pressure.read.attach(0));
      gl.uniform1i(gradienSubtractProgram.uniforms.uVelocity, velocity.read.attach(1));
      blit(velocity.write.fbo);
      velocity.swap();
    
      advectionProgram.bind();
      gl.uniform2f(advectionProgram.uniforms.texelSize, velocity.texelSizeX, velocity.texelSizeY);
      if (!ext.supportLinearFiltering) {
        gl.uniform2f(advectionProgram.uniforms.dyeTexelSize, velocity.texelSizeX, velocity.texelSizeY);
      }
      var velocityId = velocity.read.attach(0);
      gl.uniform1i(advectionProgram.uniforms.uVelocity, velocityId);
      gl.uniform1i(advectionProgram.uniforms.uSource, velocityId);
      gl.uniform1f(advectionProgram.uniforms.dt, dt);
      gl.uniform1f(advectionProgram.uniforms.dissipation, config.VELOCITY_DISSIPATION);
      blit(velocity.write.fbo);
      velocity.swap();
    
      gl.viewport(0, 0, dye.width, dye.height);
    
      if (!ext.supportLinearFiltering) {
        gl.uniform2f(advectionProgram.uniforms.dyeTexelSize, dye.texelSizeX, dye.texelSizeY);
      }
      gl.uniform1i(advectionProgram.uniforms.uVelocity, velocity.read.attach(0));
      gl.uniform1i(advectionProgram.uniforms.uSource, dye.read.attach(1));
      gl.uniform1f(advectionProgram.uniforms.dissipation, config.DENSITY_DISSIPATION);
      blit(dye.write.fbo);
      dye.swap();
    }
    
    function render(target) {
      if (config.BLOOM) {
        applyBloom(dye.read, bloom);
      }
      if (config.SUNRAYS) {
        applySunrays(dye.read, dye.write, sunrays);
        blur(sunrays, sunraysTemp, 1);
      }
    
      if (target == null || !config.TRANSPARENT) {
        gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
        gl.enable(gl.BLEND);
      } else {
        gl.disable(gl.BLEND);
      }
    
      var width = target == null ? gl.drawingBufferWidth : target.width;
      var height = target == null ? gl.drawingBufferHeight : target.height;
      gl.viewport(0, 0, width, height);
    
      var fbo = target == null ? null : target.fbo;
      if (!config.TRANSPARENT) {
        drawColor(fbo, normalizeColor(config.BACK_COLOR));
      }
      if (target == null && config.TRANSPARENT) {
        drawCheckerboard(fbo);
      }
      drawDisplay(fbo, width, height);
    }
    
    function drawColor(fbo, color) {
      colorProgram.bind();
      gl.uniform4f(colorProgram.uniforms.color, color.r, color.g, color.b, 1);
      blit(fbo);
    }
    
    function drawCheckerboard(fbo) {
      checkerboardProgram.bind();
      gl.uniform1f(checkerboardProgram.uniforms.aspectRatio, canvas.width / canvas.height);
      blit(fbo);
    }
    
    function drawDisplay(fbo, width, height) {
      displayMaterial.bind();
      if (config.SHADING) {
        gl.uniform2f(displayMaterial.uniforms.texelSize, 1.0 / width, 1.0 / height);
      }
      gl.uniform1i(displayMaterial.uniforms.uTexture, dye.read.attach(0));
      if (config.BLOOM) {
        gl.uniform1i(displayMaterial.uniforms.uBloom, bloom.attach(1));
        gl.uniform1i(displayMaterial.uniforms.uDithering, ditheringTexture.attach(2));
        var scale = getTextureScale(ditheringTexture, width, height);
        gl.uniform2f(displayMaterial.uniforms.ditherScale, scale.x, scale.y);
      }
      if (config.SUNRAYS) {
        gl.uniform1i(displayMaterial.uniforms.uSunrays, sunrays.attach(3));
      }
      blit(fbo);
    }
    
    function applyBloom(source, destination) {
      if (bloomFramebuffers.length < 2) {
        return;
      }
    
      var last = destination;
    
      gl.disable(gl.BLEND);
      bloomPrefilterProgram.bind();
      var knee = config.BLOOM_THRESHOLD * config.BLOOM_SOFT_KNEE + 0.0001;
      var curve0 = config.BLOOM_THRESHOLD - knee;
      var curve1 = knee * 2;
      var curve2 = 0.25 / knee;
      gl.uniform3f(bloomPrefilterProgram.uniforms.curve, curve0, curve1, curve2);
      gl.uniform1f(bloomPrefilterProgram.uniforms.threshold, config.BLOOM_THRESHOLD);
      gl.uniform1i(bloomPrefilterProgram.uniforms.uTexture, source.attach(0));
      gl.viewport(0, 0, last.width, last.height);
      blit(last.fbo);
    
      bloomBlurProgram.bind();
      for (var i = 0; i < bloomFramebuffers.length; i++) {
        var dest = bloomFramebuffers[i];
        gl.uniform2f(bloomBlurProgram.uniforms.texelSize, last.texelSizeX, last.texelSizeY);
        gl.uniform1i(bloomBlurProgram.uniforms.uTexture, last.attach(0));
        gl.viewport(0, 0, dest.width, dest.height);
        blit(dest.fbo);
        last = dest;
      }
    
      gl.blendFunc(gl.ONE, gl.ONE);
      gl.enable(gl.BLEND);
    
      for (var i$1 = bloomFramebuffers.length - 2; i$1 >= 0; i$1--) {
        var baseTex = bloomFramebuffers[i$1];
        gl.uniform2f(bloomBlurProgram.uniforms.texelSize, last.texelSizeX, last.texelSizeY);
        gl.uniform1i(bloomBlurProgram.uniforms.uTexture, last.attach(0));
        gl.viewport(0, 0, baseTex.width, baseTex.height);
        blit(baseTex.fbo);
        last = baseTex;
      }
    
      gl.disable(gl.BLEND);
      bloomFinalProgram.bind();
      gl.uniform2f(bloomFinalProgram.uniforms.texelSize, last.texelSizeX, last.texelSizeY);
      gl.uniform1i(bloomFinalProgram.uniforms.uTexture, last.attach(0));
      gl.uniform1f(bloomFinalProgram.uniforms.intensity, config.BLOOM_INTENSITY);
      gl.viewport(0, 0, destination.width, destination.height);
      blit(destination.fbo);
    }
    
    function applySunrays(source, mask, destination) {
      gl.disable(gl.BLEND);
      sunraysMaskProgram.bind();
      gl.uniform1i(sunraysMaskProgram.uniforms.uTexture, source.attach(0));
      gl.viewport(0, 0, mask.width, mask.height);
      blit(mask.fbo);
    
      sunraysProgram.bind();
      gl.uniform1f(sunraysProgram.uniforms.weight, config.SUNRAYS_WEIGHT);
      gl.uniform1i(sunraysProgram.uniforms.uTexture, mask.attach(0));
      gl.viewport(0, 0, destination.width, destination.height);
      blit(destination.fbo);
    }
    
    function blur(target, temp, iterations) {
      blurProgram.bind();
      for (var i = 0; i < iterations; i++) {
        gl.uniform2f(blurProgram.uniforms.texelSize, target.texelSizeX, 0.0);
        gl.uniform1i(blurProgram.uniforms.uTexture, target.attach(0));
        blit(temp.fbo);
    
        gl.uniform2f(blurProgram.uniforms.texelSize, 0.0, target.texelSizeY);
        gl.uniform1i(blurProgram.uniforms.uTexture, temp.attach(0));
        blit(target.fbo);
      }
    }
    
    function splatPointer(pointer) {
      var dx = pointer.deltaX * config.SPLAT_FORCE;
      var dy = pointer.deltaY * config.SPLAT_FORCE;
      splat(pointer.texcoordX, pointer.texcoordY, dx, dy, pointer.color);
    }
    
    function multipleSplats(amount) {
      for (var i = 0; i < amount; i++) {
        var color = generateColor();
        color.r *= 10.0;
        color.g *= 10.0;
        color.b *= 10.0;
        var x = Math.random();
        var y = Math.random();
        var dx = 1000 * (Math.random() - 0.5);
        var dy = 1000 * (Math.random() - 0.5);
        splat(x, y, dx, dy, color);
      }
    }
    
    function splat(x, y, dx, dy, color) {
      gl.viewport(0, 0, velocity.width, velocity.height);
      splatProgram.bind();
      gl.uniform1i(splatProgram.uniforms.uTarget, velocity.read.attach(0));
      gl.uniform1f(splatProgram.uniforms.aspectRatio, canvas.width / canvas.height);
      gl.uniform2f(splatProgram.uniforms.point, x, y);
      gl.uniform3f(splatProgram.uniforms.color, dx, dy, 0.0);
      gl.uniform1f(splatProgram.uniforms.radius, correctRadius(config.SPLAT_RADIUS / 100.0));
      blit(velocity.write.fbo);
      velocity.swap();
    
      gl.viewport(0, 0, dye.width, dye.height);
      gl.uniform1i(splatProgram.uniforms.uTarget, dye.read.attach(0));
      gl.uniform3f(splatProgram.uniforms.color, color.r, color.g, color.b);
      blit(dye.write.fbo);
      dye.swap();
    }
    
    function correctRadius(radius) {
      var aspectRatio = canvas.width / canvas.height;
      if (aspectRatio > 1) {
        radius *= aspectRatio;
      }
      return radius;
    }
    
    canvas.addEventListener('mousedown', function (e) {
      var posX = scaleByPixelRatio(e.offsetX);
      var posY = scaleByPixelRatio(e.offsetY);
      var pointer = pointers.find(function (p) {
        return p.id == -1;
      });
      if (pointer == null) {
        pointer = new pointerPrototype();
      }
      updatePointerDownData(pointer, -1, posX, posY);
    });
    
    canvas.addEventListener('mousemove', function (e) {
      var pointer = pointers[0];
      if (!pointer.down) {
        return;
      }
      var posX = scaleByPixelRatio(e.offsetX);
      var posY = scaleByPixelRatio(e.offsetY);
      updatePointerMoveData(pointer, posX, posY);
    });
    
    window.addEventListener('mouseup', function () {
      updatePointerUpData(pointers[0]);
    });
    
    canvas.addEventListener('touchstart', function (e) {
      e.preventDefault();
      var touches = e.targetTouches;
      while (touches.length >= pointers.length) {
        pointers.push(new pointerPrototype());
      }
      for (var i = 0; i < touches.length; i++) {
        var posX = scaleByPixelRatio(touches[i].pageX);
        var posY = scaleByPixelRatio(touches[i].pageY);
        updatePointerDownData(pointers[i + 1], touches[i].identifier, posX, posY);
      }
    });
    
    canvas.addEventListener(
      'touchmove',
      function (e) {
        e.preventDefault();
        var touches = e.targetTouches;
        for (var i = 0; i < touches.length; i++) {
          var pointer = pointers[i + 1];
          if (!pointer.down) {
            continue;
          }
          var posX = scaleByPixelRatio(touches[i].pageX);
          var posY = scaleByPixelRatio(touches[i].pageY);
          updatePointerMoveData(pointer, posX, posY);
        }
      },
      false
    );
    
    window.addEventListener('touchend', function (e) {
      var touches = e.changedTouches;
      var loop = function (i) {
        var pointer = pointers.find(function (p) {
          return p.id == touches[i].identifier;
        });
        if (pointer == null) {
          return;
        }
        updatePointerUpData(pointer);
      };
    
      for (var i = 0; i < touches.length; i++) loop(i);
    });
    
    window.addEventListener('keydown', function (e) {
      if (e.code === 'KeyP') {
        config.PAUSED = !config.PAUSED;
      }
      if (e.code === 'KeyQ') {
        splatStack.push(parseInt(Math.random() * 20) + 5);
      }
    });
    
    function updatePointerDownData(pointer, id, posX, posY) {
      pointer.id = id;
      pointer.down = true;
      pointer.moved = false;
      pointer.texcoordX = posX / canvas.width;
      pointer.texcoordY = 1.0 - posY / canvas.height;
      pointer.prevTexcoordX = pointer.texcoordX;
      pointer.prevTexcoordY = pointer.texcoordY;
      pointer.deltaX = 0;
      pointer.deltaY = 0;
      pointer.color = generateColor();
    }
    
    function updatePointerMoveData(pointer, posX, posY) {
      pointer.prevTexcoordX = pointer.texcoordX;
      pointer.prevTexcoordY = pointer.texcoordY;
      pointer.texcoordX = posX / canvas.width;
      pointer.texcoordY = 1.0 - posY / canvas.height;
      pointer.deltaX = correctDeltaX(pointer.texcoordX - pointer.prevTexcoordX);
      pointer.deltaY = correctDeltaY(pointer.texcoordY - pointer.prevTexcoordY);
      pointer.moved = Math.abs(pointer.deltaX) > 0 || Math.abs(pointer.deltaY) > 0;
    }
    
    function updatePointerUpData(pointer) {
      pointer.down = false;
    }
    
    function correctDeltaX(delta) {
      var aspectRatio = canvas.width / canvas.height;
      if (aspectRatio < 1) {
        delta *= aspectRatio;
      }
      return delta;
    }
    
    function correctDeltaY(delta) {
      var aspectRatio = canvas.width / canvas.height;
      if (aspectRatio > 1) {
        delta /= aspectRatio;
      }
      return delta;
    }
    
    function generateColor() {
      var c = HSVtoRGB(Math.random(), 1.0, 1.0);
      c.r *= 0.15;
      c.g *= 0.15;
      c.b *= 0.15;
      return c;
    }
    
    function HSVtoRGB(h, s, v) {
      var r, g, b, i, f, p, q, t;
      i = Math.floor(h * 6);
      f = h * 6 - i;
      p = v * (1 - s);
      q = v * (1 - f * s);
      t = v * (1 - (1 - f) * s);
    
      switch (i % 6) {
        case 0:
          (r = v), (g = t), (b = p);
          break;
        case 1:
          (r = q), (g = v), (b = p);
          break;
        case 2:
          (r = p), (g = v), (b = t);
          break;
        case 3:
          (r = p), (g = q), (b = v);
          break;
        case 4:
          (r = t), (g = p), (b = v);
          break;
        case 5:
          (r = v), (g = p), (b = q);
          break;
      }
    
      return {
        r: r,
        g: g,
        b: b,
      };
    }
    
    function normalizeColor(input) {
      var output = {
        r: input.r / 255,
        g: input.g / 255,
        b: input.b / 255,
      };
      return output;
    }
    
    function wrap(value, min, max) {
      var range = max - min;
      if (range == 0) {
        return min;
      }
      return ((value - min) % range) + min;
    }
    
    function getResolution(resolution) {
      var aspectRatio = gl.drawingBufferWidth / gl.drawingBufferHeight;
      if (aspectRatio < 1) {
        aspectRatio = 1.0 / aspectRatio;
      }
    
      var min = Math.round(resolution);
      var max = Math.round(resolution * aspectRatio);
    
      if (gl.drawingBufferWidth > gl.drawingBufferHeight) {
        return { width: max, height: min };
      } else {
        return { width: min, height: max };
      }
    }
    
    function getTextureScale(texture, width, height) {
      return {
        x: width / texture.width,
        y: height / texture.height,
      };
    }
    
    function scaleByPixelRatio(input) {
      var pixelRatio = window.devicePixelRatio || 1;
      return Math.floor(input * pixelRatio);
    }
    
    function hashCode(s) {
      if (s.length == 0) {
        return 0;
      }
      var hash = 0;
      for (var i = 0; i < s.length; i++) {
        hash = (hash << 5) - hash + s.charCodeAt(i);
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    }
  }
  }

  render() {
    return (
    <div className="canvas w-embed">
      <canvas>
      </canvas>
    </div>
    )
  }
}

